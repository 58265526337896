
// for Integration 
const REACT_APP_API_URL = "https://installqapi.staging2.edition1.nl"

// for Production 
// const REACT_APP_API_URL = "https://vakbladen-admin.installq.nl"
export const API_KEY_ID = '5A665CE2AE39FD54E83FBB45DF416'
export const BASE_URL = REACT_APP_API_URL

 const API_URL = REACT_APP_API_URL + "/api"


/* Auth */
export const AUTH_URLS = {
  AUTH_LOGIN_URLS: `${API_URL}/login/`,
  USER_LOGOUT_URLS: `${API_URL}/logout/`,
  RESET_PASSWORD_URLS: `${API_URL}/reset-password/`,
  CONFIRM_PASSWORD_URLS: `${API_URL}/user/confirm-password/`,
  UPDATE_PASSWORD_URLS: `${API_URL}/change-password/`,
  FCM_TOKEN_URLS: `${API_URL}/fcm-token/`,
}

/* magazine */
export const MAGAZINE_URLS = {
  GET_ALL_MAGAZINES_URLS: `${API_URL}/get-all-magazines/`,
  GET_MAGAZINES_URLS: `${API_URL}/get-magazines/`,
  GET_EDITIONS_URLS: `${API_URL}/get-editions/`,
  GET_EDITION_URLS: `${API_URL}/get-edition/`,
  SEARCH_MAGAZINE_URLS: `${API_URL}/search/`,
}
